<template>
  <div>
    <div
      v-if="showTab"
      class="
        consume-bar
        margin-top-bottom-twenty
        box-shadow-light-grey
        border-radius-6
        padding-right-left-5
      "
    >
      <el-menu
        :default-active="activeSubMenu"
        active-text-color="#00bf8a"
        mode="horizontal"
        class=""
        router
      >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/multiStatistics/multi"
          @click.native="handleTabClick('/statistics/multiStatistics/multi')"
          >累计统计</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/multiStatistics/month"
          @click.native="handleTabClick('/statistics/multiStatistics/month')"
          >月度统计</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/multiStatistics/monthDetail"
          @click.native="
            handleTabClick('/statistics/multiStatistics/monthDetail')
          "
          >月度统计详情</el-menu-item
        >
        <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/multiStatistics/costRecharge"
          @click.native="
            handleTabClick('/statistics/multiStatistics/costRecharge')
          "
          >小说成本</el-menu-item
        >
        <!-- <el-menu-item
          class="font-eighteen margin-right-23"
          index="/statistics/multiStatistics/yearDetail"
          @click.native="
            handleTabClick('/statistics/multiStatistics/yearDetail')
          "
          >年度统计</el-menu-item
        > -->
      </el-menu>
    </div>
    <div class="consume-part">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "finance",
  data() {
    return {
      tabName: this.$route.path,
      activeSubMenu: this.$route.path,
      showTab: true,
    };
  },
  watch: {
    $route(newVal) {
      this.activeSubMenu = newVal.path;
      this.tabName = newVal.path;
    },
  },
  methods: {
    handleTabClick(tab) {
      this.tabName = tab;
    },
  },
  created() {
    if (this.$route.name == "YearDetail") {
      this.showTab = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.consume-bar {
  background: #fff;
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
}
.el-menu--horizontal > .el-menu-item {
  height: 52px;
  line-height: 52px;
}
.consume-part {
  box-shadow: 0px 0px 6px 0px #f1f4f6;
}
</style>
